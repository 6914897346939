@tailwind base;
@tailwind components;
@tailwind utilities;

main{
    padding-left: 0;
    margin-left:0;
}

.mainHero{
    background-color: black
}