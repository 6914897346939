*
{
    margin: 0;
    padding: 0;
}

html
{
    /* background: #1f003b; */
    background:white;
    background:black;
    height: 100%;
    margin: 0;
    padding: 0;
}

.webgl
{
    /* position: fixed;
    top: 0;
    left: 0;
    outline: none; */

    /* position: absolute; */
    position:fixed;
    /* position:absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    /* z-index:-1 */
}


.section
{
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    font-family: 'Cabin', sans-serif;
    color: #ffeded;
    text-transform: uppercase;
    font-size: 7vmin;
    padding-left: 10%;
    padding-right: 10%;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}