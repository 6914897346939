.icons{
    color:white
}

.home{
    background-color: rgb(255, 255, 255);
}

.goHome{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:10px;  
}

.RickyLin{
    display:flex;
    justify-content: center;
    align-items: center;
    gap:10px;  
}

.hint{
    font-size: smaller;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
}

.resume{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;  
    padding-left:30px;
}


.resume-hidden {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-out, max-height 0.5s ease-out;
}

.resume-visible {
    opacity: 1;
    max-height: 100px; /* Adjust as needed */
    transition: opacity 0.5s ease-in, max-height 0.5s ease-in;
}


.search__input {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 50px;
    padding: 20px 20px;
    border-radius: 999px;
    width: 75vw;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 5px;
    max-width: 560px;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    background-color: rgb(255, 255, 255); /* Ensure the background is white */
}

.search__input > input {
    flex: 1;
    padding: 10px 20px;
    font-size: medium;
    border: none;
    outline: none; /* Remove default outline on focus */
    background: transparent; /* Ensure background is transparent */
}

.search__input > input:focus {
    outline: none;
    border: none;
}


.icons {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.search__buttons {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.search__buttons button {
    margin: 5px;
    padding: 7px 15px;
    border: 1px solid white;
    background-color: #f8f8f8;
    text-transform: inherit;
    color: #5f6368;
}

.search__buttons button:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
    background-color: #f8f8f8;
    border: 1px solid #c6c6c6;
    color: #222;
}

.search__buttonsHidden {
    display: none;
}


.search__inputContainer {
    position: relative;
    width: 100%;
}


.search__history {
    position: absolute;
    background-color: rgb(254, 6, 6);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 556px; 
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}



.search__historyItem {
    padding: 8px 12px;
    cursor: pointer;
}

.search__historyItem:hover {
    background-color: #f0f0f0;
}