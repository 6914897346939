.header {
    position: relative;
    z-index: 1000; /* Adjust as needed */
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Lower z-index for Three.js canvas */
}

.home__headerLeft{
    z-index:1000;
}

.home__headerRight{
    z-index:1000;
}

.whole{
    z-index:1000;
}

.search{
    z-index:1000;
}


.search{
    position: relative;
  }
  
  .search__history2 {
    position: absolute;
    top: 100%; /* Position right under the search input */
    left: 0;
    width: 100%; /* Full width of the parent */
    background-color: #fff; /* Background color of the dropdown */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Box shadow for dropdown */
    z-index: 10; /* Ensure it's above other content */
    max-height: 200px; /* Optional: Limit maximum height for scroll */
    overflow-y: auto; /* Optional: Enable vertical scrolling */
  }
  
  .search__historyItem2 {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Optional: Smooth hover effect */
  }
  
  .search__historyItem2:hover {
    background-color: #f0f0f0; /* Optional: Hover background color */
  }

  .search__input {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 50px;
    padding: 20px 20px;
    border-radius: 999px;
    width: 75vw;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 5px;
    max-width: 560px;
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    background-color: rgb(255, 255, 255); /* Ensure the background is white */
}


  .animated-div {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.animated-div.show {
    opacity: 1;
    transform: translateY(0);
}

.icons-container {
  opacity: 0; 
  transition: opacity 3s ease-in-out; 
}

.icons-container.show {
  opacity: 1; 
}

.white-three-stack-icon{
  z-index:1100;
}

.left-content{
  z-index:1000;
}

.right-content{
  z-index:1000;
}
