.pdf-viewer-section{
    text-align: center;
    /* max-width: 1000px; */
}

.app {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-bar {
    display: flex;
    align-items: center;
  }
  
  .google-logo {
    height: 50px;
    margin-right: 10px;
  }
  
  .search-input {
    width: 400px;
    padding: 10px;
    font-size: 16px;
  }
  
  .profile-info {
    margin-top: 20px;
  }
  
  .name-title {
    text-align: center;
  }
  
  .contact-info {
    text-align: center;
  }
  
  /* main {
    
    margin: 0 auto;
  } */
  
  section {
    margin-bottom: 20px;
  }
  
  .skill-category {
    margin-left: 20px;
  }
  