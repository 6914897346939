/* Light mode styles */
.pfp {
    width: 50px; /* Adjust as needed */
    height: auto; /* This maintains aspect ratio */
}

.Googlelogo {
    width: 300px; /* Adjust as needed */
    height: auto; /* This maintains aspect ratio */
}

.home {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: rgb(255, 255, 255); /* Light mode background */
}

.home__header {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    align-items: center;
    padding-top: 1;
}

.home__header a {
    margin-right: 20px;
    text-decoration: inherit;
    color: rgba(0, 0, 0, 0.87); /* Light mode text color */
    font-size: 15px;
}

.home__header a:hover {
    text-decoration: underline;
}

.home__headerRight {
    display: flex;
    align-items: center;
    min-width: 13vw;
    gap: 10px;
}

.home__headerRight > .MuiSvgIcon-root {
    margin-right: 20px;
}

.home__body {
    flex: 1;
    display: flex;
    margin-top: 9%;
    flex-direction: column;
}

.google__logo {
    object-fit: contain;
    width: 150px;
    height: 100px;
    transform: scale(2.65);
    margin-bottom: 10px;
}

.home__body > img {
    object-fit: contain;
}

.google__credits {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(0, 132, 234); /* Light mode text color */
    font-size: 10px;
    margin-left: 140px;
    margin-bottom: 20px;
}

/* Dark mode styles */
.dark .home {
    background-color: rgb(36, 36, 36); /* Dark mode background color */
}

.dark .home__header a {
    color: rgba(255, 255, 255, 0.87); /* Dark mode text color */
}

.dark .google__credits {
    color: rgb(0, 132, 234); /* Dark mode text color */
}
